import React from "react";
import { FormattedHTMLMessage } from "gatsby-plugin-intl";
import { Layout } from "../components/";
import ContactSheet from "../components/contactsheet";

const LegalNoticePage = () => {
  return (
    <Layout>
      <h1>Impressum</h1>
      <p>
        Informationspflicht laut §5 E-Commerce Gesetz, §14
        Unternehmensgesetzbuch, §63 Gewerbeordnung und Offenlegungspflicht laut
        §25 Mediengesetz.
      </p>
      <ContactSheet
        company={"Haus Bergkristall"}
        name={"Daniel Schütz"}
        address={"Plangeross 70"}
        zipcode={"A-6481"}
        city={"St. Leonhard"}
        phone={"+43 5413 86358"}
        mobile={"+43 660 3424067"}
        fax={"+43 5413 86358-17"}
        email={"info@haus-bergkristall.at"}
      />

      <FormattedHTMLMessage id="impressum" />
    </Layout>
  );
};

export default LegalNoticePage;
