import React from "react";
import { FormattedMessage } from "gatsby-plugin-intl";
import styled from "styled-components";

import { Column, BoldText } from "./styled";

const Container = styled(Column)`
  align-items: flex-start;
  padding: 2rem;
  & > p {
    margin: 0;
  }
`;

const ContactSheet = ({
  company,
  name,
  address,
  zipcode,
  city,
  phone,
  mobile,
  fax,
  email
}) => {
  return (
    <Container>
      <h2>
        <FormattedMessage id="contact" />
      </h2>
      {company && <BoldText>{company}</BoldText>}
      {name && <p>{name}</p>}
      {address && <p>{address}</p>}
      {zipcode && city && (
        <p>
          {zipcode} {city}
        </p>
      )}
      {phone && (
        <p>
          <FormattedMessage id="phone_abbr" /> {phone}
        </p>
      )}
      {mobile && (
        <p>
          <FormattedMessage id="mobile_abbr" /> {mobile}
        </p>
      )}
      {fax && <p>Fax: {fax}</p>}
      {email && <a href={"mailto:" + email}>E-Mail: {email}</a>}
    </Container>
  );
};

export default ContactSheet;
